@import "typography.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap");
$columns: 200;

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";
@import "swiper/scss/scrollbar";

.swiper-button-prev,
.swiper-button-next {
  color: var(--primary-dark-bg);
}

.swiper-pagination-bullet-active {
  background: var(--primary-dark-bg);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 25px;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}

* {
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
  //   Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
}

.card,
input,
mat-expansion-panel {
  border-radius: 2px !important;
}

mat-icon {
  font-family: "Material Icons" !important;
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right !important;
}

.primary-text {
  color: var(--theme-text-color) !important;
}

.text-primary {
  color: var(--theme-text-color) !important;
}

.danger-text {
  color: red !important;
}

.price-text {
  color: var(--success-color) !important;
}

@mixin icon-x {
  @for $i from 1 through $columns {
    .icon-#{$i} {
      font-size: #{$i}px;
    }

    .font-size-#{$i} {
      font-size: #{$i}px !important;
    }
  }
}

@include icon-x;

$columns: 50;

@mixin pad-x {
  @for $i from 1 through $columns {
    .pad-#{$i} {
      padding: #{$i}px !important;
    }
  }
}

@include pad-x;

@mixin pad-top-x {
  @for $i from 1 through $columns {
    .pad-top-#{$i} {
      padding-top: #{$i}px !important;
    }
  }
}

@include pad-top-x;

@mixin pad-bottom-x {
  @for $i from 1 through $columns {
    .pad-bottom-#{$i} {
      padding-bottom: #{$i}px !important;
    }
  }
}

@include pad-bottom-x;

@mixin pad-left-x {
  @for $i from 1 through $columns {
    .pad-left-#{$i} {
      padding-left: #{$i}px !important;
    }
  }
}

@include pad-left-x;

@mixin pad-right-x {
  @for $i from 1 through $columns {
    .pad-right-#{$i} {
      padding-right: #{$i}px !important;
    }
  }
}

@include pad-right-x;

$mart: 50;

@mixin mar-top-x {
  @for $i from 1 through $mart {
    .mar-top-#{$i} {
      margin-top: #{$i}px !important;
    }
  }
}

@include mar-top-x;

$marr: 50;

@mixin mar-right-x {
  @for $i from 1 through $marr {
    .mar-right-#{$i} {
      margin-right: #{$i}px !important;
    }
  }
}

@include mar-right-x;

$marb: 50;

@mixin mar-bottom-x {
  @for $i from 1 through $marb {
    .mar-bottom-#{$i} {
      margin-bottom: #{$i}px !important;
    }
  }
}

@include mar-bottom-x;

$marl: 50;

@mixin mar-left-x {
  @for $i from 1 through $marl {
    .mar-left-#{$i} {
      margin-left: #{$i}px !important;
    }
  }
}

@include mar-left-x;

$width: 100;

@mixin width-perc-x {
  @for $i from 1 through $width {
    .width-perc-#{$i} {
      width: #{$i}#{"%"} !important;
    }
  }
}

@include width-perc-x;

$height: 30;

@mixin height-x {
  @for $i from 1 through $height {
    .height-#{$i} {
      height: #{$i}#{"px"} !important;
    }
  }
}

@include height-x;

@mixin width-x {
  @for $i from 1 through $width {
    .width-#{$i} {
      width: #{$i}px !important;
    }
  }
}

@include width-x;

:root {
  --body-bg: #f9fafb;
  --sidebar-bg: #02256c;
  --sidebar-bg-expanded: #1b4baa;
  --sidebar-color: #fff;
  --sidebar-active-color: #feca47;
  --card-header-bg: #d7e8f5;
  --card-header-color: #02256c;
  --theme-text-color: #02256c;
  --danger: #fbedee;
  --danger-light: #fbedee;
  --danger-color: #a76076;
  --text-danger: #f80404;
  --success-light: #e1faf8;
  --success-color: #169474;
  --primary-dark-bg: #03266d;
  --primary-light: #f1f5f9;
  --primary-color: #a2c6e9;
  --dashboard-body-bg: #fcfbfe;
  --border-color: #e5e5e5;
  --white-background: #ffffff;
  --border-color-1: #d5e7fa;
  --text-primary: #02256c;
  --text-primary-light: #02256c;
  --text-light: #aaaaaa;
  --header-text: #4c4c4c;
  --menu-border: #aaaeb8;
  --background-btn-yellow: #ffc107;
  --profile-text: #444444;
  --background-hover: #f1f5f9;
  --background-btn-default: #e6eaee;
  --background-btn-secondary: #9bbdde;
  --icon-btn-secondary: #5a84ad;
  --background-menu-selected: #02256c;
  --text-menu-selected: #feca47;
  --purple-color: #581358;
  --bs-body-color: rgba(0, 0, 0, 0.87);
}

body {
  background: var(--body-bg);
}

.mat-form-field {
  font-size: 12px;
}

.mat-header-cell {
  font-size: 10px;
}

.mat-header-row,
.mat-row,
.mat-footer-row {
  min-height: 35px;
}

.mat-body,
.mat-body-1,
.mat-typography {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-option-text {
  font-size: 12px !important;
  font-family: Roboto, "Helvetica Neue", sans-serif !important;
}

.mat-cell,
.mat-footer-cell {
  font-size: 12px;
}

.template-container {
  background: #ffff;
  padding: 20px 60px 60px 60px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.cursor {
  cursor: pointer;
}

.cursor-zoom {
  cursor: zoom-in;
}

.btn-color {
  background: #0059a6;
  color: #ffff;
}

.title-color {
  color: #3f51b5;
}

.edit-icon {
  // margin-right: 10px;
  cursor: pointer;
  height: 28px !important;
}

// .panel-header {
//   // box-shadow: 3px 3px 8px #0000000f;
//   z-index: 1;
//   font-size: 0.875rem;
//   border-left-width: 4px;
//   border-left-style: solid;
//   padding: 5px 10px 5px 20px ;
//   background-color: white;
//   border-left-color: #3f51b5;
// }

.panel-title {
  background: #e7f3f8;
  border: 1px solid #d9d6ea;
  height: 34px;
  color: black;
  padding-left: 10px;
  vertical-align: middle;
  line-height: 32px;
  font-size: 10px;
  margin-bottom: 7px;
}

.attribute-title {
  display: flex;
}

.panel-container {
  background: #ffff;
  padding: 20px 10px 60px 15px;
  border: 1px solid rgba(0, 0, 0, 0.09);
}

.mat-dialog-content {
  overflow: visible !important;
}

.dialog-container {
  display: grid;
}

.dialog-title {
  justify-self: center;
  font-weight: 400;
  color: #1ca4d4;
  font-size: 17px;
}

.delete-icon {
  color: #f44336;
  cursor: pointer;
}

mat-form-field.mat-form-field.mat-form-field-appearance-outline
  > div.mat-form-field-wrapper
  > div.mat-form-field-flex
  > div.mat-form-field-infix {
  padding: 0.4em;
}

.mat-form-field-appearance-outline .mat-form-field-label {
  top: unset;
  margin-top: unset;
}

.mat-form-field-label-wrapper {
  overflow: unset !important;
}

.mat-form-field-outline-start,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-end,
.mat-form-field-appearance-outline
  .mat-form-field-outline-thick
  .mat-form-field-outline-gap {
  border-width: 1px !important;
  border-color: #d5e7fa !important;
}

.mat-form-field-subscript-wrapper {
  margin-top: -0.7em;
  display: flex;
  justify-content: flex-end;

  .mat-error,
  .mat-hint {
    display: block;
    width: fit-content;
    background: white;
    padding: 0 5px;
  }
}

.flex {
  display: flex;

  &.columns {
    flex-direction: column;
    row-gap: 5px;

    &.align-center {
      align-items: center;
    }

    &.justify-center {
      justify-content: center;
    }

    &.space-between {
      justify-content: space-between;
    }
  }

  &.rows {
    flex-direction: row;
    column-gap: 5px;

    &.wrap {
      flex-wrap: wrap;
    }

    &.align-center {
      align-items: center;
    }

    &.align-start {
      align-items: flex-start;
    }

    &.justify-end {
      justify-content: flex-end;
    }

    &.space-between {
      justify-content: space-between;
    }
  }

  &.row-wrap {
    flex-wrap: wrap;
  }

  $flexLimit: 10;

  @mixin flex-x {
    @for $i from 1 through $flexLimit {
      .flex-#{$i} {
        flex: #{$i};
      }
    }
  }

  @include flex-x;
}

$growColumns: 10;

@mixin flex-grow-x {
  @for $i from 1 through $growColumns {
    .flex-grow-#{$i} {
      flex-grow: #{$i};
    }
  }
}

@include flex-grow-x;

.img-toggle {
  border: #c4c4c4 1px solid;
  border-radius: 5px;
  width: fit-content;

  .toggle-item {
    width: 30px;
    cursor: pointer;
    height: 100%;
    border-radius: 3px;
    background-color: white;
    padding: 3px;

    &.active {
      background-color: #009743;
    }
  }
}

.error-message {
  color: #f44336 !important;
}

.list-table {
  table {
    border-collapse: separate;
    border-spacing: 0 1em;
    border-bottom: 1px solid lightgray;

    .first-element,
    td:first-child {
      width: 7px;
      background-color: #ff8686;
      border-radius: 25%;
    }

    td,
    th {
      padding: 10px 0 10px 0;
    }

    tr {
      border-collapse: separate;
      border-spacing: 0 1em;
    }

    tr {
      border: 1px solid lightgray;
      border-radius: 25%;
    }

    td {
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
    }

    td:first-child {
      border-right: 1px solid lightgray;
    }

    td:last-child {
      border-right: 1px solid lightgray;
    }

    thead {
      background-color: #e5e5e5;
      text-transform: uppercase;

      th {
        padding: 10px 0 10px 0;
      }

      tr {
        border-collapse: separate;
        border-spacing: 0 0em !important;
      }
    }

    button {
      width: 100%;
    }
  }
}

.pointer {
  cursor: pointer;
}

mat-label {
  background: white;
  z-index: 10;
  padding: 0px 4px;
}

button {
  border-radius: 2px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9bb5cf;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9bb5cf;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9bb5cf;
}

// .fs-12 b{
//   font-size: 14px;
// }

.datatable-container-editable {
  mat-cell {
    div {
      width: 100%;
    }
  }
  input {
    padding: 2px !important;
    font-size: 12px;
  }
}

input {
  padding: 5px !important;
}

button:focus {
  box-shadow: none !important;
}

.mat-form-field-outline {
  background: white;
  color: #d5e7fa !important;
}

.mat-expansion-panel-body {
  padding: 10px 10px 0px 20px !important;
}

input,
select,
textarea {
  font-size: 12px;
  border: none;
  outline: none !important;

  &:focus {
    outline: none;
  }
}

table {
  width: 100%;
}

.b-red {
  border-left: solid 2px red !important;
}

.b-all-red {
  border: solid 1px red !important;
}

.b-warning {
  border: solid 2px rgb(255, 143, 58) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:disabled {
  background: #f0f0f0;
}

.delete-btn {
  background-color: red !important;
  border: none !important;
}

.delete-icon {
  color: red !important;

  mat-icon {
    color: red !important;
  }
}

.mat-form-field input {
  height: 11px !important;
}

.mat-form-field select {
  height: 32px !important;
}

.mat-form-field-label-wrapper {
  top: -4px;
}

.main-title-head {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #02256c;
  background-color: var(--card-header-bg);
  padding: 5px 10px;
  text-transform: capitalize;
}

select:disabled {
  opacity: 0.7 !important;
}

.attribute.two {
  min-width: 100%;
}

.attribute.three {
  min-width: 100%;

  select {
    width: 100%;
    background: transparent !important;
  }
}

.attribute.two.disabled {
  background: #f0f0f0;

  label {
    background: transparent;
  }
}

.attribute.tabular-sheet {
  border: none;

  input {
    min-width: 50px !important;
  }

  .toggle {
    border: 1px solid #d5e7fa;
    display: flex;
    text-wrap: nowrap;
    button {
      background: transparent;
      border: none;
      min-width: 80px;
      height: 30px;
      justify-content: center;
      width: max-content;
    }

    button.active {
      background: #a2c6e9;
      color: var(--theme-text-color) !important;
    }
  }
}

//new scss

.attribute {
  // margin-right: 32px;
  border: 1px solid #d9e9fb;
  color: #02256c;
  position: relative;

  label {
    z-index: 999;
    position: absolute;
    top: -8px;
    left: 0px;
    background: #fff;
    font-size: 11px !important;
    font-weight: 400 !important;
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    font-style: italic !important;
    padding-right: 2px;
    padding-left: 2px;
  }

  .attribute-input {
    display: flex;
    align-items: center;

    .uom-label {
      font-size: 12px;
      background-color: var(--primary-light) !important;
      height: 30px;
      display: flex;
      align-items: center;
      width: 100%;
      width: fit-content;
      white-space: pre;
      min-width: 100px;
    }

    mat-icon.more_vert {
      color: var(--card-header-bg);
      cursor: pointer;
    }

    input {
      width: 100%;
      // background: transparent;
      border: none;
      padding: 6px !important;

      &:hover,
      &:focus {
        outline: none;
      }
    }

    textarea {
      width: 100%;
      background: transparent;
      border: none;
      padding: 3px 6px 3px 6px !important;

      &:hover,
      &:focus {
        outline: none;
      }
    }

    select {
      // padding: 8px 0px 8px 0px;
      // min-width: 100px;
      width: fit-content;
      background: var(--primary-light);
      color: #444444;
      border: none;
      z-index: 101;
      min-width: 100px;

      &:hover,
      &:focus {
        outline: none;
      }
    }
  }

  .attribute-select {
    select {
      width: 100%;
      background: transparent;
      color: #444444;
      border: none;

      &:hover,
      &:focus {
        outline: none;
      }
    }
  }

  mat-icon.cancel_outline {
    position: absolute;
    padding: 0;
    margin: 0;
    border: none;
    top: -10px;
    right: -20px;
    font-size: 18px;
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 100%;
    cursor: pointer;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;

    img {
      width: 22px;
      height: 22px;
      position: absolute;
      left: -14.8px;
      top: -2.6px;
      z-index: 200;
    }
  }

  i.cancel_outline {
    position: absolute;
    padding: 0;
    margin: 0;
    border: none;
    top: -10px;
    right: -20px;
    font-size: 18px;
    width: 16px;
    height: 16px;
    background: transparent;
    border-radius: 100%;
    cursor: pointer;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: "Material Icons" !important;

    img {
      width: 22px;
      height: 22px;
      position: absolute;
      left: -14.8px;
      top: -2.6px;
      z-index: 200;
    }
  }
}

i.cancel_outline_new {
  width: 20px;
  position: absolute;
  top: -10px;
  font-size: 14px;
  left: 99%;
  height: 14px;
  z-index: 105;
  color: var(--theme-text-color);
  cursor: pointer;
  font-family: "Material Icons" !important;
  font-style: normal;
}

mat-icon.cancel_outline_new {
  position: absolute;
  top: -10px;
  font-size: 14px;
  right: -6px;
  height: 14px;
  width: 15px;
  z-index: 105;
  color: var(--theme-text-color);
  cursor: pointer;
}

.mb-0 {
  .attr-b {
    margin-bottom: 0px !important;
  }
  margin-bottom: 0px !important;
}

.attr-b {
  margin-bottom: 16px !important;
}

.attr-top {
  margin-top: 16px !important;
}

.process-table {
  font-size: 12px !important;

  // padding:3px;
  // Material Cost
  table {
    table-layout: fixed;

    thead {
      background: var(--primary-light);
      width: 100%;

      text-transform: uppercase;
      height: 30px !important;

      tr {
        th {
          text-align: left;
          font-size: 12px;
          color: #02256c;
          font-weight: 500;
          padding-left: 7px;
          border-left: 1px solid #d5e7fa !important;
          height: 30px !important;
          text-wrap: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // max-width: 40px ;
          // min-width: 40px ;
          // width:40px;
          //  padding: 1px 3px 1px 3px !important;

          .group {
            display: flex;
            align-items: center;
            justify-content: end;

            select {
              background: transparent;
              border: none;
              font-size: 12px;
              text-decoration: underline;
              color: #02256c;

              &:focus {
                outline: none;
              }
            }
          }
        }

        td {
          text-align: left;
          font-size: 12px;
          color: #02256c;
          font-weight: 400;
          height: 30px !important;
          // max-width: 40px ;
          // min-width: 40px ;
          // width:40px;
          padding: 0px !important;
        }

        th.number-column {
          text-align: end;
          padding-right: 0.2rem;
          div {
            text-align: end;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          height: 29px !important;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          input,
          select,
          div {
            width: 100%;
            border: 1px solid #d5e7fa;
            padding: 4px;
            color: #444444;
            text-align: left;
            border-radius: 2px;
            height: 28px !important;
            white-space: nowrap;

            &::-webkit-input-placeholder {
              /* WebKit, Blink, Edge */
              color: #9bb5cf;
            }

            &:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #9bb5cf;
              opacity: 1;
            }

            *::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #9bb5cf;
              opacity: 1;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #9bb5cf;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #9bb5cf;
            }

            &::placeholder {
              /* Most modern browsers support this now. */
              color: #9bb5cf;
            }
          }
        }

        th {
          height: 30px !important;
          text-align: left;
        }

        td.actions {
          display: flex;
          align-items: center;
          height: 30px !important;
          column-gap: 5px;
        }

        td.countrycode-column {
          overflow: unset !important;
          display: inline-flex !important;
          column-gap: 2px;
          width: 100%;
        }

        td.countrycode-column {
          div {
            border: none !important;
          }
        }

        td.number-column {
          text-align: end;
          font-weight: bold;
          padding-right: 0.2rem;
          div,input {
            text-align: end;
          }
        }
      }
    }
  }

  td:nth-child(1) {
    width: 50px;
  }

  th:nth-child(1) {
    width: 50px;
    border-left: none !important;
  }

  td:last-child {
    width: 125px;
  }

  th:last-child {
    width: 100px;
  }

  td.weight {
    div {
      background-color: var(--primary-light);
      color: var(--danger-color) !important;
      border: 1px solid #d5e7fa !important;
    }
  }

  td.volume {
    div {
      background-color: var(--primary-light);
      color: var(--purple-color) !important;
      border: 1px solid #d5e7fa !important;
    }
  }
}

.no-padding-th {
  th {
    padding-left: unset !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input,
select {
  // font-size: 12px !important;
  height: 28px !important;
}

// Conversion Cost
section.conversionCost {
  padding: 10px 20px;
  display: flex;

  ul {
    button {
      background: transparent;
      border: none;

      &:focus {
        outline: none;
      }

      mat-icon {
        font-size: 1.4rem;
      }
    }

    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      margin-right: 28px;
      margin-bottom: 10px;
      width: 300px;
      position: relative;
      display: flex;
      align-items: center;

      .cnvrsn-frm {
        display: flex;
        align-items: center;
        border-radius: 2px;
        background: var(--primary-light) !important;
        border: 1px solid #dbeafa;

        input,
        select {
          border: none;
          padding: 5px;
          color: #02256c;
          font-size: 12px;

          &:focus {
            outline: none;
          }
        }

        select.conversion-cost {
          background: #fff;
          margin: 1px;
        }

        input.currency {
          // background: #f1f1f1 !important;
          width: 60px;
          padding: 0 10px;
        }

        select.unit {
          width: 80px;
          height: 100%;
          padding: 5px 5px;
          border: none;
          background: var(--primary-light);
        }
      }

      button {
        position: absolute;
        top: -14px;
        right: -28px;
        background: transparent;
        border: none;
        padding: 0;
        margin: 0;

        mat-icon {
          font-size: 1rem;
        }
      }
    }
  }
}

mat-expansion-panel {
  background: #fff;
  border-radius: 0;
  border: 1px solid #dfedfb;
  margin-bottom: 2px;
  box-shadow: none !important;

  section.section-card {
    // border: 1px solid #dfedfb;
    background: #fff;
    margin: 0px 0;
  }

  mat-expansion-panel-header {
    background: var(--card-header-bg) !important;
    height: 35px !important;
    width: 100%;
    padding: 5px 10px 5px 20px !important;

    &:hover,
    &:focus {
      background: var(--card-header-bg);
      color: var(--card-header-color);
    }

    mat-panel-description {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      margin: 0;
      padding: 0;
    }
  }
}

.card {
  // margin-bottom: 10px;
  background: #ffffff;
  // border: 1px solid #dfedfb;
  box-sizing: border-box;
  border-radius: 1px;

  .card-header {
    background: var(--card-header-bg);
    color: var(--card-header-color);
    font-size: 14px;
    font-weight: 500;
    border: none;
    box-sizing: border-box;
    border-radius: 0;
    height: 35px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px 5px 20px !important;

    .card-header-option {
      text-align: left;
      position: absolute;
      left: 15px;
      top: 4px;
    }
  }

  .card-header.card-info {
    background-color: #ddecff;
  }

  .collapsable {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.4;
  }

  .card-body {
    min-height: 150px;
    max-height: 150px;
    overflow-y: auto;
  }
}

.card-header-action {
  color: var(--card-header-color) !important;
  text-align: center;
  margin: 0;
  padding: 0;
  font-weight: 500;
}

.header-light {
  background: var(--primary-light);
  border: 1px solid #d4e8fb;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  position: relative;
  height: 35px;
  padding: 5px 10px 5px 20px;

  .action-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .opt button.btn {
      border: 1px solid var(--card-header-bg);
      color: var(--card-header-bg);
      background: transparent;
      padding: 5px 5px;
      display: grid;
      text-align: center;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 35px;
    }

    .add-new-process button.btn.add-process-btn {
      width: 100%;
      background: #02256c;
      color: #fff;
      padding: 5px 20px;
      border-color: #02256c;
    }

    .opt {
      button.btn {
        border-radius: 2px;
        margin: 0 2px;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      button.btn.active {
        border-color: #02256c;
      }
    }
  }
}

.panel-header {
  width: 100%;
  display: grid;
  align-items: center;
  color: var(--text-primary);
  background: transparent;
  border: none;
  margin-right: 5px;
  height: 35px;
  padding: 5px 10px 5px 20px;
  font-size: 14px;

  .process-input-output {
    display: grid;

    select,
    input {
      width: 100%;
      color: var(--text-primary);
      border: none;
      margin-right: 5px;
    }

    .input {
      border-right: 1px solid var(--text-primary);
      padding: 0px 20px;
    }

    .output {
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .process-calculation {
    display: grid;
    align-items: center;

    .products-and-conversion-counts {
      width: 100%;
      display: grid;
      font-size: 12px;
      line-height: 16px;
    }

    .units {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px !important;
      line-height: 16px;

      .currency-value {
        display: grid;
      }

      .unit-value {
        display: grid;

        select {
          height: 100% !important;
          background: transparent;
          text-decoration: underline;
          border: none;
          color: #02256c;
          font-size: 12px;

          &:focus {
            outline: none;
          }
        }
      }
    }

    .action-btn {
      width: 100%;
      justify-content: center;
    }
  }
}

.text-align-end {
  text-align: end !important;
}

section.material-cost-calculation,
section.conversion-cost-calculation,
.raw-material {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--text-primary);
    padding: 5px 10px 5px 20px;

    .title {
      display: flex;
      align-items: center;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
        letter-spacing: 0.02em;
        color: var(--text-primary);
      }

      button {
        background: transparent;
        border: none;
        font-size: 1.2rem !important;
      }
    }

    .optional-values {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .density {
        margin-right: 80px;
        display: flex;
        align-items: center;

        .value {
          display: flex;
          align-items: center;
          margin-left: 20px;

          select {
            background: transparent;
            border: none;
            color: var(--text-primary);
            text-decoration: underline;

            &:focus {
              outline: none;
            }
          }
        }
      }

      .units {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px !important;
        line-height: 16px;

        .currency-value {
          display: grid;
          color: #34a083;
        }

        .unit-value {
          margin-left: 20px;
          display: grid;
          color: #408aef;

          select {
            height: 100% !important;
            background: transparent;
            border: none;
            color: #408aef;
            font-size: 12px;

            &:focus {
              outline: none;
            }
          }
        }
      }
    }
  }
}

td.product__img {
  cursor: pointer;
  position: relative;

  .display__product__img {
    position: absolute;
    top: 10px;
    left: 25px;
    display: none;
    background: #fff;
    border: 1px solid #dedede;
    padding: 5px;
    border-radius: 2px;
    transition: all 2s;

    img {
      width: 60px;
      height: 60px;
      // object-fit: cover;
    }
  }

  img.product-img:hover + .display__product__img {
    display: block;
    transition: all 2s;
  }
}

.padding-bar {
  padding: 5px 20px 5px 20px;
}

.toolbar {
  text-wrap: nowrap;
  display: grid;
  justify-content: space-between;
  align-items: center;
  background: var(--card-header-bg) !important;
  padding: 5px 10px 5px 20px;
  height: 35px;

  .options {
    display: grid;
    align-items: center;
    justify-content: flex-end;
  }
}

mat-dialog-container {
  background: #fff;
  padding: 0 !important;
  min-width: 400px;
}

section.card {
  padding: 20px 20px 0px 20px;

  ul.attribute-groups {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    li.attribute-group {
      list-style: none;
      flex-wrap: wrap;
      display: flex;
      align-items: center;
      width: 100%;
    }

    .relative.row {
      .labeltype-attribute {
        position: absolute;
        top: -10px;
        background: white;
        width: fit-content;
        font-weight: 500;
        color: var(--text-primary);
      }
    }
  }
}

.min-h-label {
  min-height: 50px;
}

.package-attributes {
  .relative.row {
    .labeltype-attribute {
      position: absolute;
      top: -10px;
      background: white;
      width: fit-content;
      font-weight: 500;
      color: var(--text-primary);
    }
  }
}

.selection-area {
  display: flex;
  align-items: center;
  margin-bottom: 1%;
  font-size: 14px;

  .selection-area-heading,
  .selection-area-sub-heading {
    margin: 0;
    padding: 0;
    color: #02256c;
  }

  .selection-area-heading {
    margin-right: 5%;
  }
}

.selection-area-body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  table {
    width: 200px;
    border: 1px solid #d5e7fa;
    border-radius: 4px;
    margin: 2px 2px;

    thead {
      tr {
        th {
          background: #a2c6e9;
          padding: 5px 20px;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        background: #fff;

        td {
          padding: 5px;
          border: 1px solid #d8d9eb;
        }
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.label-attribute {
  label {
    color: #02256c;
  }

  .parent-div {
    border: 1px solid #d9e9fb;
    padding: 14px 10px;
  }
}

.radio-selection {
  display: flex;
  align-items: center;

  label {
    color: #02256c;
    margin: 0 10px;
  }
}

.b-top-primary {
  border-top: solid 1px #02256c;
}

.b-all {
  border: solid 1px #d5e7fa;
}

.b-primary-all {
  border: solid 1px #02256c !important;
}

.b-bottom-primary {
  border-bottom: solid 1px #02256c !important;
}
.b-bottom-secondary {
  border-bottom: solid 1px #d5e7fa !important;
}

.b-primary-all-dark {
  border: solid 2px #02256c !important;
}

.relative {
  position: relative;
}

.check {
  align-items: center;

  span {
    margin-left: 5px;
    font-size: 12px;
    color: var(--primary-dark-bg);
  }
}

#pAttribute {
  padding: 3px;
  border: 1px solid #d9e9fb;
  color: #02256c;
}

.title-text {
  color: #02256c;
  font-weight: 500;
  font-size: 14px;
  width: max-content;
}

.bg-secondary {
  background-color: #d5e7fa !important;
}

.bg-secondary-light {
  background-color: #9bbdde !important;
}

.bg-light {
  background-color: var(--primary-light) !important;
}

.bg-primary {
  background-color: var(--primary-dark-bg) !important;
}

.bg-primary-light {
  background-color: var(--primary-color) !important;
}

.bg-blue {
  background-color: tab;
}

.cost-table {
  // table
  table {
    font-family: Roboto, "Helvetica Neue", sans-serif !important;
    border-collapse: collapse;
    width: 100%;
  }

  th {
    color: #02256c;
    border: 1px solid #d5e7fa;
    padding-right: 4px;
    padding-left: 4px;
    text-align: right;

    select {
      outline: none;
      background: none;
      border: none;
    }
  }

  td {
    color: #02256c;
    border: 1px solid #d5e7fa;
    padding: 0px;
    height: 30px;
    text-align: right;

    input {
      // border: 1px solid #d5e7fa !important;
      color: #02256c;
      text-align: end;
      font-weight: bold;
    }

    div {
      padding-right: 4px;
      padding-left: 4px;
      font-weight: bold;
      text-align: right;
    }
  }

  .tab {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.status {
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  background-color: var(--success-color);
  border-radius: 10px;
  text-wrap: nowrap;
}

// May be Global styles - Start
.wizard-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  padding-bottom: 12px;
  border-radius: 6px;
  // padding-top: 12px;
  column-gap: 15px;
  width: -webkit-fill-available;
  overflow: auto;
  margin-bottom: 10px;
  text-wrap: nowrap;

  .status {
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    color: white;
    background-color: var(--success-color);
    border-radius: 10px;
    text-wrap: nowrap;
    height: 30px;
  }

  .wizard-heading {
    font-size: 18px;
    line-height: 24px;
    color: #444444;
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  .content-title {
    color: var(--text-primary);
    font-size: 18px;
    background: #f1f5f9;
  }

  .content-info {
    color: var(--text-primary);
    font-size: 18px;
    display: flex;
    align-items: center;
  }
}

.invisible-tabs > .mat-tab-header {
  display: none;
}

.mat-steps {
  // margin-top:-42px;
  .mat-tab-label-active {
    background: var(--primary-dark-bg) !important;
    color: white !important;
  }

  .mat-tab-label {
    margin: 2px !important;
    height: 32px !important;
    padding: 5px !important;
    opacity: 1 !important;
    border-radius: 5px !important;
    background: var(--primary-light);
    border: 1px solid #d9e9fb;
    color: var(--primary-dark-bg);
    min-width: 140px !important;
  }

  .mat-ink-bar {
    display: none !important;
  }
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  border: 2px solid var(--bs-success);
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
}

.app-container {
  padding: 5px 25px;
  width: 95vw;
}

mat-expansion-panel.panel-no-padding {
  .mat-expansion-panel-body {
    padding: 0px !important;
  }
}

.b-light {
  border: 1px solid #d5e7fa;
}

.bg-card-header {
  background-color: var(--card-header-bg) !important;
}

.b-none {
  border: none !important;
}

.radio-selection {
  // border-bottom: solid 2px var(--primary-dark-bg);
  margin: 5px;
  font-weight: 700;
}

input[type="radio"] {
  -webkit-appearance: none;
  outline: 0.1em solid var(--primary-dark-bg) !important;
  outline-offset: 0.12em;
  padding: 3px !important;
  width: 15px !important;
  height: 15px !important;
}

input[type="radio"]:checked {
  display: inline-block;
  background-color: var(--primary-dark-bg) !important;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px !important;
  height: 16px !important;
  border: 1px solid var(--theme-text-color) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: 11px !important;
  background-color: white;
  outline: none;
}

input[type="checkbox"]:checked {
  background-image: url("../assets/img/rectangle.svg") !important;
}

/* width */
::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary-dark-bg);
  border-radius: 10px;
}

.mat-autocomplete-panel {
  mat-option {
    height: 28px;
  }

  box-shadow: none;

  mat-option:focus {
    background-color: #0082ed;
    color: white;
  }
}

.tab-common {
  .mat-tab-body-wrapper {
    padding-bottom: 0% !important;
  }
}

.mat-tab-body-wrapper {
  padding-bottom: 5%;
  // min-width: 1000px;
  overflow: auto;
}

.prefer-uom-grid-scroll {
  display: flex;
  overflow: scroll;
  width: 550px;
}

.package-attributes {
  padding: 20px 20px 0px 20px;
}

// related to print templates

app-print-templates {
  /* hide the printing component from @media screen */
  display: none;
}

@media print {
  /* invert the display (show/hide) properties of the main */
  /* aplication component and the printing component       */

  app-print-templates {
    display: block;
  }

  .view-content {
    display: none;
  }
}

.mat-tab-body-content {
  width: 100% !important;
  height: unset !important;
  overflow: unset !important;
}

.ng-select.custom {
  border: 0px;
  min-height: 30px;
  height: 30px;
}

.ng-select.custom-home {
  height: 100% !important;
}

.custom-home {
  .attribute {
    height: 100% !important;
  }
}
.ng-select.custom-home .ng-select-container {
  border-radius: 24px !important;
  height: 100% !important;
}

.ng-select.custom-multiple-table.ng-select-multiple
  .ng-select-container
  .ng-value-container
  .ng-value {
  margin-bottom: 2px !important;
  margin-right: unset !important;
  margin-left: unset !important;
}

.ng-select.custom-multiple-table.ng-select-multiple
  .ng-select-container
  .ng-value-container {
  flex-wrap: unset !important;
}
.disabled-option {
  color: var(--card-header-color);
}
.ng-select.typeahead {
  width: 100% !important;

  .ng-select-container {
    width: 100% !important;
    max-width: unset !important;
  }
}

.ng-dropdown-panel.ng-select-bottom {
  // max-width: fit-content !important;
}

.ng-select.custom .ng-select-container {
  height: 30px;
  border: 1px solid #d5e7fa !important;
  overflow: hidden;
  min-height: 30px;
  font-size: 12px;
  border-radius: 0px;
  z-index: 998 !important;

  input {
    padding: unset !important;
  }
}

.process-table {
  td.b-none {
    .ng-select {
      div.ng-select-container,
      .ng-placeholder {
        border: none !important;
        min-height: 24px !important;
      }
    }
  }
}

.ng-select.custom-table .ng-select-container {
  height: 28px !important;
  border: 1px solid #d5e7fa !important;
  overflow: inherit;
  min-height: 28px;
  font-size: 12px;
  border-radius: 0px;
  min-width: 90px;
  max-width: 90px;
  z-index: 998 !important;
  input {
    padding: unset !important;
  }
}

.ng-select.custom .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 12px;
  padding: 5px;
}

.ng-select.custom .ng-select-container .ng-value-container .ng-input {
  top: unset !important;
}

.ng-select.custom-table .ng-select-container .ng-value-container .ng-input {
  top: unset !important;
}

.uom-config-section {
  position: fixed;
  right: 0;
  z-index: 100;

  button {
    float: right;
    top: 110px;
    right: -40px;
    position: fixed;
    z-index: 100;
    white-space: nowrap;
    transform: rotate(90deg);
  }

  .uom-card {
    position: absolute;
    right: -2px;
    z-index: 100;
    margin-top: 10px;
    min-width: 220px;
  }
}

i.mat-icon {
  font-family: "Material Icons" !important;
  font-style: unset;
  color: var(--text-primary);
  font-size: 22px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

i.material-icon {
  font-family: "Material Icons" !important;
  font-style: unset;
  color: var(--text-primary);
  font-size: 20px;
  width: 20px;
  cursor: pointer;
  vertical-align: bottom;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.bg-disable {
  background: #ebebeb !important;
}

.mat-expansion-padding {
  padding: 20px 10px 0px 20px !important;
}

.pl-1 {
  padding-left: 0.8rem;
}

//related to table

.custom-header {
  .mat-sort-header-container {
    width: 100%;
  }

  .mat-sort-header-content {
    width: 100%;
    text-align: left !important;
  }
}

.mat-sort-header-content {
  text-align: left !important;
  min-width: max-content;
}

.tile-layout {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
}

.tile-container {
  display: grid;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  height: 120px;

  line-height: 120px;
  font-size: large;
  border: 1px solid #d5e7fa;
  background: #f1f5f9;
  align-content: center;

  .title-span {
    line-height: 20px;
    color: var(--text-color);
    text-decoration: none !important;

    span {
      margin-top: 10px;
      font-weight: 400;
      font-size: 14px;
    }
  }

  img {
    filter: brightness(0) saturate(100%) invert(13%) sepia(25%) saturate(6349%)
      hue-rotate(212deg) brightness(93%) contrast(106%);
  }
}

.h-30 {
  height: 30px;
}

// mat table styles

.header-wrapper {
  max-width: 89vw;
}

mat-table {
  background: transparent !important;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--card-header-color);
}

mat-header-row,
mat-row {
  border-bottom-width: 0 !important;
  margin: 0;
}

mat-header-row {
  border-radius: 0;
  background: var(--card-header-bg);
  color: var(--card-header-color);
}

mat-row {
  border: 1px solid #f4f5f6 !important;
  border-radius: 0;
  background: #f1f5f9;
}

mat-row:nth-child(2n) {
  background: #fff;
}

.tooltip .tooltip-arrow {
  display: none !important;
}

.tooltip.show {
  opacity: 1 !important;
  font-size: 14px !important;
}

.tooltip-inner {
  max-width: 300px;
  color: black !important;
  text-align: left;
  background-color: white !important;
  border-radius: 0rem;
  padding: 5px;
  font-size: 14px !important;
  z-index: 10000;
  border: solid 1px var(--background-btn-secondary);
}

.cdk-overlay-container {
  z-index: 1000 !important; /* Ensure tooltip is above other components */
}

.vendor-steps {
  .mat-tab-body-wrapper {
    padding-bottom: unset !important;
  }
}

// related to color coding for product type

.master-product {
  background-color: rgb(214, 246, 217);
  color: rgb(14, 85, 12);
  width: 20px !important;
  min-width: 20px !important;
}

.self-product {
  background-color: rgb(239, 203, 193);
  color: rgb(195, 42, 31);
  width: 20px !important;
  min-width: 20px !important;
}

.third-product {
  background-color: var(--primary-color);
  color: solid 1px var(--primary-dark-bg);
  width: 18px !important;
  height: 18px !important;
  min-width: 18px !important;
}

.master-product-row {
  background-color: #f4fef5 !important;
  border: solid 2px white;
}

.self-product-row {
  background-color: #faf1ee !important;
  border: solid 2px white;
}

.third-product-row {
  background-color: var(--primary-light) !important;
  border: solid 2px white;
}

.tooltip-white {
  background: white;
  position: absolute;
  margin-top: 35px;
  height: 50px;
  width: 275px;
  z-index: 1002;
  padding: 10px;
  overflow-y: auto;
  overflow-x: clip;
}

.file-box {
  margin-bottom: 10px;
  width: 100%;
  height: 100px;
  border: solid 1px var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary-color);
}

.star-icon-size {
  color: var(--background-btn-yellow) !important;
}

@media screen and (max-width: 768px) {
  .mobile-view-dialog {
    width: 80% !important;
    max-width: unset !important;

    mat-dialog-container {
      min-width: unset !important;
    }
  }

  .qc-detail-dialog {
    height: 90vh;
    overflow: hidden;
    width: 100% !important;
    max-width: unset !important;
  }

  .guideline-dialog {
    height: 90vh;
    overflow: auto;
    width: 100% !important;
    max-width: unset !important;
  }
  .slider-dialog {
    height: 90vh;
    overflow: hidden;
    width: 100% !important;
    max-width: unset !important;
  }
}

.qc-detail-dialog {
  height: 90vh;
  overflow: hidden;
  width: 80%;
  max-width: unset !important;
}

input.ng-invalid.ng-touched {
  border: red solid 1px;
}

select.ng-invalid.ng-touched {
  border: red solid 1px;
}

textarea.ng-invalid.ng-touched {
  border: red solid 1px;
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #f0f0f0;
}

.signup-contain-details {
  overflow: hidden;

  .signup-bottom {
    background-color: var(--primary-light);
    padding: 4px 0;
    height: 30px;
    bottom: 0;
    position: fixed;
    width: 100%;

    .signup-btm-txt {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .signup-btm-txt p {
      font-weight: 400;
      margin-bottom: 0;
      color: var(--text-light);
    }

    .signup-btm-txt a {
      text-decoration: none;
      font-weight: 400;
      margin-bottom: 0;
      color: var(--text-light);
    }
  }

  .signup-txt {
    h2 {
      font-weight: 500;
      font-size: 30px;
      line-height: 50px;
      color: var(--text-primary);
      margin-bottom: 0;
    }

    p {
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 20px;
      color: var(--text-primary);
      line-height: 1.3;
    }
  }

  .signin-right {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .signin {
    padding: 5%;
    margin-bottom: 10%;
    height: 97vh;
  }

  .account-all {
    height: calc(100vh - 300px) !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;

    ul {
      li {
        a.account-details {
          background: var(--sidebar-color);
          border: 1px solid var(--border-color-1);
          border-radius: 2px;
          display: flex;
          width: 100%;
          padding: 12px 30px 12px 14px;
          text-decoration: none;
          margin: 10px 0;

          &:hover {
            border: 2px solid var(--text-primary);
          }

          .account-contains {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .account-all-txt {
              margin-left: 13px;
              width: 100%;

              .account-upper-txt {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h6 {
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 21px;
                  color: var(--text-primary);
                  margin-bottom: 0;
                }
              }

              .account-below-txt {
                display: flex;
                margin-top: 8px;

                p {
                  font-weight: 400;
                  font-size: 12px;
                  line-height: 20px;
                  color: var(--text-primary);
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        a.selected {
          border: 2px solid var(--text-primary);
        }
      }
    }

    .choose-btn {
      margin-top: 60px;

      .btn-signup {
        color: var(--text-primary);
        border-radius: 2px;
        text-align: center;
        width: 100%;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        padding: 15px 0;
      }
    }
  }
}

.mat-column-rfQuotationId,
.mat-column-status,
.mat-column-paymentStatus {
  max-width: 150px !important;
}

.mat-column-saleBy {
  max-width: 80px !important;
}
.mat-column-requestFromName {
  min-width: 150px !important;
}
.mat-column-productTypeDescription {
  min-width: 220px !important;
}
.mat-column-productSubTypeDescription {
  min-width: 230px !important;
}
.mat-column-relationAccountName {
  min-width: 150px !important;
}
.mat-column-relationStatusId {
  max-width: 150px !important;
}

.mat-column-addressLine {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-column-phoneNumber {
  max-width: 130px !important;
}

.mat-column-cost {
  min-width: 170px !important;
}
.mat-column-transactionId {
  min-width: 150px !important;
}
.mat-column-productCode {
  div {
    justify-content: start !important;
  }
  min-width: 220px !important;
}

.mat-column-lastModifiedByName {
  max-width: 60px !important;
}
.mat-column-addressZipCode {
  max-width: 80px !important;
}

.text-ellipse {
  overflow: hidden;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.mat-column-date,
.mat-column-requiredByDate,
.mat-column-salesRepName,
.mat-column-createdDate,
.mat-column-code {
  max-width: 120px !important;
}
.mat-column-productTemplateName {
  min-width: 250px !important;
}

.mat-column-action {
  justify-content: flex-end;
  display: flex !important;
  max-width: 100px !important;

  .mat-sort-header-content {
    width: 100%;
    text-align: end;
  }
}

.mat-cell.mat-column-action {
  padding-right: 0.2rem !important;
}

.mat-tab-label {
  justify-content: left !important;
  padding: unset !important;
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  z-index: 9999; /* Make sure it's on top */
}

.mat-paginator-container {
  width: unset !important;
}

// mat-paginator {
//   position: fixed;
//   width: 89vw;
//   bottom: 50px;
// }

.close-icon-option {
  display: none;
}

.ng-option-selected {
  .close-icon-option {
    display: block;
  }
}

.mat-drawer-container {
  height: calc(100vh - 45px);
}
.dashed-border{
  border: dashed 1px var(--primary-dark-bg);
  border-radius: 10px;
}